import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useEffectAsync } from '../reactHelper';
import t from '../common/localization';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SelectField = ({
  margin,
  variant,
  label,
  multiple,
  value,
  emptyValue = 0,
  onChange,
  endpoint,
  data,
  dataDisabled = [],
  keyGetter = item => item.id,
  titleGetter = item => item.name,
}) => {
  const [items, setItems] = useState(data);

  useEffectAsync(async () => {
    if (endpoint) {
      const response = await fetch(endpoint);
      if (response.ok) {
        setItems(await response.json());
      }
    }
  }, []);

  if (items) {
    return (
      <FormControl margin={margin} variant={variant} fullWidth={true}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          value={value}
          onChange={onChange}>
          {!multiple && emptyValue !== null &&
            <MenuItem value={emptyValue}>{t('none')}</MenuItem>
          }
          {items.sort((a, b) => titleGetter(a).localeCompare(titleGetter(b))).map(item => (
            <MenuItem disabled={dataDisabled.includes(keyGetter(item))} key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null;
  }
}

export default SelectField;

export const SelectWithSearch = ({label, items, item, onChange, style}) => {
  
  const [autocomplete, setAutocomplete] = useState({id: 0, name: '', initial: true});

  useEffect(() => {
    if (item && autocomplete.initial) {
      setAutocomplete({id: item, name: (items[item] && items[item].name) ? items[item].name : ''})
    }
  }, [item, autocomplete]);

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <Autocomplete
        style={style}
        value={autocomplete}
        onChange={(event, value) => { setAutocomplete(value); onChange(Number(value ? value.id : 0)) }}
        options={Object.values(items).sort((a, b) => a.name.localeCompare(b.name))}
        getOptionLabel={(option) => { return `${option.name}`}}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      />
    </FormControl>
  );

}
