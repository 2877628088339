export const getDeviceQrCodeUrl = (device, size=500) => {
  if (device.attributes && device.attributes.license_plate)
    return `report/barcode.php?text=${encodeURIComponent(device.attributes.license_plate)}&size=${size}`
}

export async function downloadDeviceQrCode(device, size=500) {
  if (device.attributes && device.attributes.license_plate) {
    var fileName = device.attributes.license_plate
    fileName = fileName.replaceAll("/", "");
    fileName = fileName.replaceAll("\\", "");

    fetch(getDeviceQrCodeUrl(device, size))
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob], { type: "image/png" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName+'.png');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading image:', error));    
  }
}