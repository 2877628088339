import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportMonthlyKm = () => {
  global.selectedMenu = "/report/km_grid"

  const report_name = t('reportMonthlyKm')
  const endpoint = '/report/report_monthly_km.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage
    setItems={setItems}
    endpoint={endpoint}
    reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    showMonthSelector={true}
    >
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportMonthlyKm;
