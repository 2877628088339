import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, MenuItem, ListItemText, FormControl, InputLabel, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkField from '../form/LinkField';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';
import { getDeviceName } from '../map/mapUtil';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const RefillOperatorPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  return (
    <EditItemView endpoint="../report/api.php/refill_operators" item={item} setItem={setItem} title={ t('refill_operators') + ' > ' + addEditString(t('addOperator'), t('editOperator')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.username || ''}
                onChange={event => setItem({...item, username: event.target.value})}
                label={t('username')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                type="password"
                onChange={event => setItem({...item, password: event.target.value})}
                label={t('password')}
                autoComplete="new-password"
                />
                
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                autoComplete="off"
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.code || ''}
                onChange={event => setItem({...item, code: event.target.value})}
                label={t('code')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.phone || ''}
                onChange={event => setItem({...item, phone: event.target.value})}
                label={t('phone')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.alert_emails || ''}
                onChange={event => setItem({...item, alert_emails: event.target.value})}
                label={t('alertEmails')}
                />
                
            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default RefillOperatorPage;
