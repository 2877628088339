import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, FormControl, Checkbox, Grid, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormLabel from '@material-ui/core/FormLabel';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const RefillPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [item, setItem] = useState({});
  const [itemLoaded, setItemLoaded] = useState(false);

  const [device, setDevice] = useState(0);
  const [driver, setDriver] = useState(0);
  const [newDeviceId, setNewDeviceId] = useState(0);

  moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

  var devices = useSelector(state => Object.values(state.devices.items));
  var drivers = useSelector(state => state.drivers.items);
  var positions = useSelector(state => Object.values(state.positions.items));

  //var deviceId = history.location.data.selectedId;
  var deviceId = global.extraData;

  if (!deviceId) history.push(`/management/summary`)
  
  const get_device_name = (id) => {
    var result = ''
    devices.forEach(function(device, index) {
      if (device.id == id) {
        result = device.attributes.license_plate
      }
    });
    return result;
  }

  useEffect(() => {
    if (!itemLoaded && item.id && drivers) {
      setDriver(drivers[item.driverid]);
      setItemLoaded(true);
    }
  }, [item]);

  useEffect(() => {
    if (!item.hasOwnProperty('cost_date')) {
      item.cost_date = moment().format('YYYY-MM-DD HH:mm:ss')
    }    
  }, [item]);
  
  const fuel_types = [
    { "id": "diesel", "name": "Gasóleo" },
    { "id": "gasoline", "name": "Gasolina" },
  ];

  const get_device_current_km = (deviceId) => {
    positions.forEach(function(position, index) {
      if (position.deviceId == deviceId) {
        return Math.round(position.attributes.totalDistance/1000, 0)
      }
    });
  }

  const get_device_current_hours = (deviceId) => {
    positions.forEach(function(position, index) {
      if (position.deviceId == deviceId) {
        return Math.round(position.attributes.hours/1000/3600, 0)
      }
    });
  }

  return (
    <EditItemView endpoint={`../report/api.php/refill/${deviceId>0 ? deviceId : newDeviceId}`} item={item} setItem={setItem} title={ t('refills') + ' > ' + (deviceId>0 ? get_device_name(deviceId) + ' > ' : '') + addEditString(t('addRefill'), t('editRefill'))}>
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              { global.extraData == -1 &&
              <Autocomplete
                options={devices.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)}
                onChange={(event, item) => { setDevice(item); setNewDeviceId(item.id) }}
                value={device}

                disableClearable={true}
                disableListWrap={true}
                fullWidth={true}

                style={{ marginTop: '10px', marginBottom: '20px' }}

                getOptionLabel={(device) => get_device_name(device.id)}

                renderInput={(params) => 
                  <TextField {...params} label={t('vehicle')} />
                }
              />
              }
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  label="Data do abastecimento"
                  format="DD/MM/yyyy HH:mm:ss"
                  ampm={false}
                  variant="dialog"
                  value={item.cost_date}
                  onChange={event => setItem({...item, cost_date: event.format('YYYY-MM-DD HH:mm:ss')})}
                />
              </MuiPickersUtilsProvider>

              <TextField
                margin="normal"
                variant="outlined"
                value={item.cost || ''}
                onChange={event => setItem({...item, cost: event.target.value})}
                label={"Custo (" + global.currency + ")"}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.invoice || ''}
                onChange={event => setItem({...item, invoice: event.target.value})}
                label="Nr. da factura"
                />

              <Autocomplete
                options={Object.values(drivers).sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)}
                onChange={(event, driver) => { setDriver(driver); setItem({...item, driverid: driver.id}) }}
                value={driver}

                disableClearable={true}
                disableListWrap={true}
                fullWidth={true}

                style={{ marginTop: '10px', marginBottom: '20px' }}

                getOptionLabel={(driver) => drivers[driver.id] ? drivers[driver.id].name : ''}

                renderInput={(params) => 
                  <TextField {...params} label={t('driver')} />
                }
              />   

              <SelectField
                margin="normal"
                value={item.fuel_type || ''}
                emptyValue={null}
                onChange={event => setItem({...item, fuel_type: event.target.value})}
                data={fuel_types}
                label="Tipo de combustível"
                />

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.litres || ''}
                onChange={event => setItem({...item, litres: event.target.value})}
                label="Litros"
                />

              <Grid container spacing={0}>
                <Grid item>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    value={item.km || ''}
                    onChange={event => setItem({...item, km: event.target.value})}
                    label="Km do veículo"
                    />
                </Grid>
                <Grid item style={{position: 'relative'}}>
                  <Button
                    variant="outlined"
                    style={{position: 'absolute', bottom: '15px', left: '10px'}}
                    color="primary"
                    onClick={event => setItem({...item, km: get_device_current_km(deviceId)})}>Km&nbsp;Actuais
                    </Button>
                </Grid>  
              </Grid>

              <Grid container spacing={0}>
                <Grid item>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    value={item.hours || ''}
                    onChange={event => setItem({...item, hours: event.target.value})}
                    label="Horas do veículo"
                    />
                </Grid>
                <Grid item style={{position: 'relative'}}>
                  <Button
                    variant="outlined"
                    style={{position: 'absolute', bottom: '15px', left: '10px'}}
                    color="primary"
                    onClick={event => setItem({...item, hours: get_device_current_hours(deviceId)})}>Horas&nbsp;Actuais
                    </Button>
                </Grid>  
              </Grid>           

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.details || ''}
                onChange={event => setItem({...item, details: event.target.value})}
                label="Detalhes"
                />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default RefillPage;
