import React, { useState } from 'react';
import ReportLayoutPage from './ReportLayoutPage';
import ReportLayoutData from './ReportLayoutData';
import ReportLayoutMessage from './ReportLayoutMessage';

import t from '../common/localization';

const ReportMonthlyHours = () => {
  global.selectedMenu = "/report/km_grid"

  const report_name = t('reportMonthlyHours')
  const endpoint = '/report/report_monthly_hours.php'

  const [items, setItems] = useState([]);

  return (
    <ReportLayoutPage
    setItems={setItems}
    endpoint={endpoint}
    reportName={report_name}
    showVehicles={false}
    showDrivers={false}
    showVehiclesOrDrivers={true}
    showMonthSelector={true}
    extraFieldList = {{
      "order": "check1",
      "check1_enabled": true,
      "check1_value": false,
      "check1_name": t('showVehicleDriverDetails'),
    }}
    >
    <ReportLayoutMessage items={items}></ReportLayoutMessage>
      <ReportLayoutData items={items}></ReportLayoutData>
    </ReportLayoutPage>
  );
};

export default ReportMonthlyHours;
