import React, { useState } from 'react';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionDetails, makeStyles } from '@material-ui/core';
import { addEditString } from '../common/stringUtils';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DriverPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({});

  return (
    <EditItemView endpoint="drivers" item={item} setItem={setItem} title={ t('drivers') + ' > ' + addEditString(t('addDriver'), t('editDriver')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.phone ? item.attributes.phone : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, phone: e.target.value}})}
                label={t('phone')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.email ? item.attributes.email : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, email: e.target.value}})}
                label="e-mail"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.notes ? item.attributes.notes : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, notes: e.target.value}})}
                label={t('notes')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.uniqueId && item.uniqueId.startsWith('random_') ? '' : (item.uniqueId || '')}
                onChange={event => setItem({...item, uniqueId: event.target.value})}
                label={t('rfidId')}
                />

              <TransferList
                title={t('associatedGroups')}
                item={item}
                setItem={setItem}
                element="associated_groups"
                available={["store", "groups"]}
                associated={["api", "/report/api.php/groups", "driverId"]}
                />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default DriverPage;
