import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import Blank from './Blank';
import { Permissions } from './Permissions';
import LoginPage from './LoginPage';
import SocketController from './SocketController';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

//https://mui.com/customization/default-theme/
const theme = createTheme({
  typography: {
    fontFamily: [
      'Proxima Nova Regular',
      'Roboto',
      'Arial'
    ].join(','),
    fontWeightLight: 600,
    fontWeightRegular: 600,
    fontWeightMedium: 600,
    fontSize: 14,
  },
});

const App = () => {
  const initialized = useSelector(state => !!state.session.server && !!state.session.user);
  const user = useSelector(state => state.session.user);

  if (initialized) {
    global.beta = false;
    global.map_reloadGeofences = false;
    global.map_goToInitialPosition = true;
    global.pendingCommands = [];
    global.popupsRegistered = false;
    global.popupsRegisteredPoi = false;
    global.extraData = false;
    global.selectedMenu = '';
    global.listWidth = 0;
    global.listHeight = 0;
    global.isLandscape = true;
    global.followDeviceId = false;
    global.has_canbus = false;
    global.has_pto = false;
    global.streetImageCache = [];
    global.currency = null;
    global.client_details = null;
    global.showReportEcoDriving = false;
    global.showReportRag = false;

    global.mainView = {};
    global.mainView.searchField = "";
    global.mainView.groupField = 0;
    global.mainView.tabValue = "vehicles";
    global.mainView.vehicleTabFilter = "all";
    global.mainView.geofenceTabFilter = "circle";

    global.replay = {};
    global.replay.device = false;
    global.replay.period = "today";
    global.replay.from = false;
    global.replay.to = false;

    global.reports = {};
    global.reports.period = "today";
    global.reports.from = false;
    global.reports.to = false;
    global.reports.devices = [];
    global.reports.drivers = [];
    global.reports.passengers = [];
    global.reports.geofences = [];
    global.reports.groups = [];

    global.management = {};
    global.management.period = "total";

    global.searchFieldColor = 'rgba(0, 0, 0, 0.1)'
    global.searchField = {};

    global.refillPanelSearchText = '';
    global.refillPanelFromDate = false;
    global.refillPanelToDate = false;

    global.showLiters = {};
    global.showHours = {};
    global.levelDisplay = {};
    global.showExtraInfo = {};

    if (typeof(global.user_details) == 'undefined') {
      global.user_details = "";
    }
    if (typeof(global.language) == 'undefined') {
      global.language = "";
    }

    document.onkeydown = keydown;
    document.onkeyup = keyup;

    function keydown(evt)
    {
      global.ctrlKey = evt.ctrlKey;
      if (evt.ctrlKey && evt.altKey && evt.keyCode==48) //CTRL+ALT+0
      {
        document.cookie.split('; ').forEach(cookie => {
          cookie = cookie.split('=')
          if (cookie[0] == 'JSESSIONID') {
            prompt("Session:", "session="+cookie[1])
          }
        });
      }

      if (!!evt.ctrlKey && !!evt.altKey && evt.keyCode==66) //CTRL+ALT+B
      {
        global.beta = !global.beta;
        if (global.beta) {
          alert('Activadas funcionalidades BETA')
        } else {
          alert('Desactivadas funcionalidades BETA')
        }        
      }
    }

    function keyup(evt)
    {
      global.ctrlKey = evt.ctrlKey;
    }
  }

  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SocketController />
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <Route>
          {!initialized ? (<LinearProgress />) : (
            <Switch>
              { Permissions(user).filter(permission => permission.enabled).map(permission => 
                  permission.items.filter(item => item.enabled || item.allow_beta).map(item => 
                    item.routes.map(route => <Route exact path={route.path} component={route.component} />)
              ))}
              <Route component={Blank} />
            </Switch>
          )}
        </Route>
      </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
